.wordcloud {
    position: relative;
    .top-section {
        padding: rem-calc(80 0);
        background: $gray;
        .inner {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            column-gap: rem-calc(10);
            .left {
                h1 {
                    letter-spacing: rem-calc(-0.3);
                    padding-bottom: rem-calc(35);
                }
                p {
                    font-size: rem-calc(18);
                    line-height: rem-calc(26);
                    font-weight: 500;
                    letter-spacing: rem-calc(-0.3);
                }
            }
            .right {
                svg {
                    max-width: rem-calc(310);
                    margin: 0px auto;
                    height: auto;
                }
            }
        }
    }
    .wordcloud-holder {
        padding: rem-calc(50 0);
        ul {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            row-gap: rem-calc(50);
            column-gap: rem-calc(35);
            li {
                h2 {
                    font-size: rem-calc(52);
                    padding-bottom: rem-calc(35);
                    font-weight: 900;
                }
                div {
                    display: flex;
                    flex-flow: column;
                    grid-template-columns: unset;
                    row-gap: unset;
                    p {
                        a {
                            font-size: rem-calc(16);
                            line-height: rem-calc(24);
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .wordcloud {
        position: relative;
        .top-section {
            padding: rem-calc(40 0);
            background: $gray;
            .inner {
                display: grid;
                grid-template-columns: 1fr;
                align-items: center;
                column-gap: rem-calc(10);
                row-gap: rem-calc(35);
                .left {
                    h1 {
                        letter-spacing: rem-calc(-0.3);
                        padding-bottom: rem-calc(20);
                        font-size: rem-calc(32);
                        line-height: rem-calc(42);
                    }
                    p {
                        font-size: rem-calc(16);
                        line-height: rem-calc(24);
                        font-weight: 500;
                        letter-spacing: rem-calc(-0.3);
                    }
                }
                .right {
                    svg {
                        max-width: rem-calc(250);
                        margin: 0px auto;
                        height: auto;
                    }
                }
            }
        }
        .wordcloud-holder {
            padding: rem-calc(35 0);
            ul {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                row-gap: rem-calc(35);
                column-gap: rem-calc(0);
                li {
                    h2 {
                        font-size: rem-calc(32);
                        padding-bottom: rem-calc(20);
                        font-weight: 900;
                    }
                    div {
                        display: flex;
                        flex-flow: column;
                        grid-template-columns: unset;
                        row-gap: unset;
                        p {
                            a {
                                font-size: rem-calc(16);
                                line-height: rem-calc(24);
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }   
}