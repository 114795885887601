.loan-list {
    padding: rem-calc(45 0);
    position: relative;
    display: grid;
    grid-template-columns: 6.125fr 3fr;
    column-gap: rem-calc(30);
    margin: rem-calc(0 -24);
    .left {
        display: grid;
        row-gap: rem-calc(12);
        .only-mob {
            margin-top: rem-calc(25);
            display: none;
            button {
                border-radius: rem-calc(4);
                background-color: $text;
                border-color: $green;
                box-shadow: $bs;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                padding: rem-calc(0 24);
                height: rem-calc(62);
                font-weight: bold;
                font-size: rem-calc(17);
                outline: none;
                font-family: 'Inter', sans-serif;
                letter-spacing: rem-calc(-0.3);
                text-transform: unset;
            }
            svg {
                margin-left: rem-calc(10);
            }
        }
        .single-loan {
            box-shadow: $bs;
            padding: rem-calc(15 24 15);
            border-radius: rem-calc(12);
            
            .top {
                margin-bottom: rem-calc(12);
                display: flex;
                justify-content: space-between;
                align-items: center;
                h3 {
                    font-weight: 600;
                    font-size: rem-calc(16);
                    line-height: rem-calc(24);
                    letter-spacing: rem-calc(-0.3);
                }
                .rentescore {
                    display: flex;
                    align-items: center;
                    padding: rem-calc(0 17);
                    height: rem-calc(38);
                    border-radius: rem-calc(25);
                    svg {
                        margin: rem-calc(0 10);
                        width: rem-calc(28);
                    }
                    &.pos {
                        background-color: transparentize($color: $green, $amount: 0.85);
                        svg {
                            fill: $green;
                        }
                    }
                    &.neg {
                        background-color: transparentize($color: $red, $amount: 0.85);
                        svg {
                            fill: $red;
                        }
                    }
                }
            }
            .bottom {
                display: flex;
                justify-content: space-between;
                flex-flow: column;
                margin: rem-calc(0 -48 0 0);
                left: rem-calc(-24);
                position: relative;
                .data-info {
                    padding: rem-calc(0 24);
                    &:nth-child(odd) {
                        background: $gray;
                    }
                    display: flex;
                    justify-content: space-between;
                    flex-flow: row;
                    height: rem-calc(28);
                    align-items: center;
                    p, h4 {
                        font-size: rem-calc(14);
                        line-height: rem-calc(24);
                        font-weight: normal;
                    }
                    span {
                        font-size: rem-calc(16);
                        line-height: rem-calc(24);
                        font-weight: 600;
                    }
                }
            }
        }
    }
    .right {
        .box {
            box-shadow: $bs;
            padding: rem-calc(50 25 25);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            border-radius: rem-calc(4);
            background: $lightgreen;
            p {
                color: $text;
                font-size: rem-calc(20);
                line-height: rem-calc(28);
                margin: 0;
                letter-spacing: rem-calc(-0.3);
                font-weight: 500;
                &.small {
                    font-size: rem-calc(12);
                    line-height: rem-calc(16);
                    text-align: center;
                    max-width: rem-calc(270);
                }
            }
            h3 {
                font-size: rem-calc(40);
                line-height: rem-calc(28);
                padding-bottom: rem-calc(25);
                font-weight: 900;
                letter-spacing: rem-calc(-0.3);
            }
            button {
                border-radius: rem-calc(4);
                background-color: $text;
                border-color: $green;
                box-shadow: $bs;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                padding: rem-calc(0 24);
                height: rem-calc(62);
                font-weight: bold;
                font-size: rem-calc(17);
                outline: none;
                font-family: 'Inter', sans-serif;
                letter-spacing: rem-calc(-0.3);
                text-transform: unset;
                svg {
                    margin-left: rem-calc(10);
                }
                &.close-btn {
                    background-color: transparent;
                    color: #333;
                    border-radius: rem-calc(4);
                }
                svg {
                    margin-left: rem-calc(10);
                }
            }
        }
    }
}



.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active,
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: $green !important;
}
.css-rnmm7m-MuiPaper-root-MuiDialog-paper {
    padding: rem-calc(20 35) !important;
}
.css-hph623-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:last-of-type) {
    border-left: 1px solid #e0e0e0 !important;
    border-top-left-radius: rem-calc(4) !important;
    border-bottom-left-radius: rem-calc(4) !important;
}

@media screen and (max-width: 992px) {
    .css-rnmm7m-MuiPaper-root-MuiDialog-paper {
        margin: 0 !important;
        max-width: 100% !important;
        width: 100% !important;
        max-height: 100% !important;
        min-height: 100vh;
        border-radius: 0px !important;
    }
    .css-rnmm7m-MuiPaper-root-MuiDialog-paper {
        padding: rem-calc(5 15) !important;
    }
    .css-91yet9-MuiTypography-root-MuiDialogTitle-root {
        padding: rem-calc(16 12 8) !important;
    }
    .css-14yr603-MuiStepContent-root {
        padding-left: 12px !important;
        padding-right: 15px !important;
    }
    .css-uojkt5>:not(style) {
        width: 100% !important;
    }
}

@media screen and (max-width: 992px) {
    .loan-list {
        padding: rem-calc(45 0);
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        column-gap: rem-calc(30);
        row-gap: rem-calc(35);
        .left {
            .only-mob {
                margin-top: rem-calc(25);
                display: flex;
                align-items: center;
                justify-content: center;
                button {
                    border-radius: rem-calc(4);
                    background-color: $text;
                    border-color: $green;
                    box-shadow: $bs;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    padding: rem-calc(0 24);
                    height: rem-calc(62);
                    font-weight: bold;
                    font-size: rem-calc(17);
                    outline: none;
                    font-family: 'Inter', sans-serif;
                    letter-spacing: rem-calc(-0.3);
                    text-transform: unset;
                }
                svg {
                    margin-left: rem-calc(10);
                }
            }
            p {
                &.desc {
                    text-align: center;
                }
            }
            display: grid;
            row-gap: rem-calc(12);
            order: 1;
            .single-loan {
                box-shadow: $bs;
                padding: rem-calc(15 24 15);
                border-radius: rem-calc(12);
                .top {
                    margin-bottom: rem-calc(12);
                    display: flex;
                    flex-flow: column;
                    justify-content: space-between;
                    align-items: center;
                    h3 {
                        font-weight: 600;
                        font-size: rem-calc(16);
                        line-height: rem-calc(24);
                        letter-spacing: rem-calc(-0.3);
                        padding-bottom: rem-calc(10);
                    }
                    .rentescore {
                        display: flex;
                        align-items: center;
                        padding: rem-calc(0 17);
                        margin-bottom: rem-calc(10);
                        height: rem-calc(30);
                        border-radius: rem-calc(25);
                        svg {
                            margin: rem-calc(0 10);
                            width: rem-calc(24);
                        }
                        &.pos {
                            background-color: transparentize($color: $green, $amount: 0.85);
                            svg {
                                fill: $green;
                            }
                        }
                        &.neg {
                            background-color: transparentize($color: $red, $amount: 0.85);
                            svg {
                                fill: $red;
                            }
                        }
                    }
                }
                .bottom {
                    display: flex;
                    justify-content: space-between;
                    flex-flow: column;
                    margin: rem-calc(0 -48 0 0);
                    left: rem-calc(-24);
                    position: relative;
                    .data-info {
                        padding: rem-calc(0 24); 
                        &:nth-child(odd) {
                            background: $gray;
                        }
                        display: flex;
                        justify-content: space-between;
                        flex-flow: row;
                        height: rem-calc(28);
                        align-items: center;
                        p, h4 {
                            font-size: rem-calc(14);
                            line-height: rem-calc(24);
                            font-weight: normal;
                        }
                        span {
                            font-size: rem-calc(16);
                            line-height: rem-calc(24);
                            font-weight: 600;
                        }
                    }
                }
            }
        }
        .right {
            .box {
                box-shadow: $bs;
                padding: rem-calc(35 10);
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column;
                border-radius: rem-calc(4);
                background: $lightgreen;
                p {
                    color: $text;
                    font-size: rem-calc(18);
                    line-height: rem-calc(24);
                    padding-bottom: rem-calc(10);
                    margin: 0;
                    letter-spacing: rem-calc(-0.3);
                    font-weight: 500;
                }
                h3 {
                    font-size: rem-calc(32);
                    line-height: rem-calc(28);
                    padding-bottom: rem-calc(20);
                    font-weight: 900;
                    letter-spacing: rem-calc(-0.3);
                }
                button {
                    border-radius: rem-calc(4);
                    background-color: $text;
                    border-color: $green;
                    box-shadow: $bs;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    padding: rem-calc(0 24);
                    height: rem-calc(62);
                    font-weight: bold;
                    font-size: rem-calc(17);
                    outline: none;
                    font-family: 'Inter', sans-serif;
                    letter-spacing: rem-calc(-0.3);
                    text-transform: unset;
                    svg {
                        margin-left: rem-calc(10);
                    }
                    &.close-btn {
                        background-color: transparent;
                        color: #333;
                        border-radius: rem-calc(4);
                    }
                    svg {
                        margin-left: rem-calc(10);
                    }
                }
            }
        }
    } 
}





.affiliate {
    padding: rem-calc(45 0);
    position: relative;
    margin: rem-calc(0 0);
    .affiliate-holder {
        padding: rem-calc(0 0 0 0);
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        row-gap: rem-calc(25);
        .single-affiliate {
            border-radius: rem-calc(15);
            background-color: $gray;
            padding: rem-calc(30 30);
            display: grid;
            grid-template-columns: 2fr 7fr 2fr;
            .image-wrapper {
                display: flex;
                position: relative;
                img {
                    width: 100%;  
                    max-width: rem-calc(150);
                    object-fit: contain;
                    height: auto;
                }
            }
            .info {
                grid-template-columns: repeat(4, 1fr);
                column-gap: rem-calc(30);
                display: grid;
                margin: rem-calc(0 20);
                div {
                    display: flex;
                    flex-flow: column;
                    align-items: center;
                    padding-bottom: rem-calc(10);
                    p {
                        span {
                            font-size: rem-calc(20)
                        }
                        font-size: rem-calc(16);
                    }
                }
                .eff-rent {
                    border-top: rem-calc(1) dashed #bdbdbd;
                    max-width: 90%;
                    margin: 0px auto;
                    grid-column-start: 1;
                    grid-column-end: 5;
                    padding: 0;
                    padding-top: rem-calc(10);
                    p {
                        font-size: rem-calc(14);
                        color: lighten($text, 10%);
                    }
                }
            }
            .misc {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                .detail-button {
                    height: rem-calc(50);
                    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='25' ry='25' stroke='%23707070FF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='100' stroke-linecap='square'/%3e%3c/svg%3e");
                    border-radius: rem-calc(25);
                    color: $text;
                    text-transform: uppercase;
                    padding: rem-calc(0 25);
                    font-size: rem-calc(14);
                    letter-spacing: rem-calc(0.3);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    margin-right: rem-calc(28);
                    display: none;
                    span {
                        &:before {
                            font-size: rem-calc(18);
                            margin-left: rem-calc(5);
                        }
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
                .affiliate-button {
                    text-decoration: none;
                    background-color: $green;
                    color: white;
                    border-radius: rem-calc(25);
                    box-shadow: $bs;
                    height: rem-calc(50);
                    padding: rem-calc(0 25);
                    font-size: rem-calc(14);
                    letter-spacing: rem-calc(0.3);
                    text-transform: uppercase;
                    width: rem-calc(135);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    transition: $bezier;
                    span {
                        &:before {
                            font-size: rem-calc(18);
                            margin-left: rem-calc(8);
                        }
                    }
                    &:hover {
                        transition: $bezier;
                        background-color: lighten($green, 5%);
                    }
                    p {
                        &.ad {
                            position: absolute;
                            top: rem-calc(5);
                            text-transform: capitalize;
                            font-size: rem-calc(14);
                            color: lighten($text, 10%);
                        }
                    }
                }
            }
            .details {
                grid-column-start: 1;
                grid-column-end: 3;
                padding-top: rem-calc(50);
                display: none;
                h1, h2, h3 {
                    margin: 0;
                    padding-bottom: rem-calc(25);
                }
                p {
                    margin: 0;
                    color: $text;
                    font-size: rem-calc(16);
                    line-height: rem-calc(24);
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .affiliate {
        padding: rem-calc(25 0);
        position: relative;
        margin: rem-calc(0 -15);
        .affiliate-holder {
            padding: rem-calc(15 0 0 0);
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            row-gap: rem-calc(25);
            .single-affiliate {
                border-radius: rem-calc(15);
                background-color: $gray;
                padding: rem-calc(25 25 35);
                display: grid;
                grid-template-columns: 1fr;
                row-gap: rem-calc(35);
                .image-wrapper {
                    display: flex;
                    position: relative;
                    justify-content: center;
                    img {
                        width: 100%;
                        max-width: rem-calc(120);
                        object-fit: contain;
                        height: auto;
                    }
                }
                .info {
                    grid-template-columns: repeat(1, 1fr);
                    column-gap: rem-calc(0);
                    display: grid;
                    div {
                        display: flex;
                        flex-flow: row-reverse;
                        align-items: center;
                        justify-content: space-between;
                        padding-bottom: rem-calc(8);
                        p {
                            span {
                                font-size: rem-calc(16)
                            }
                            font-size: rem-calc(14);
                        }
                    }
                    .eff-rent {
                        border-top: rem-calc(1) dashed #bdbdbd;
                        max-width: 100%;
                        margin: 0px auto;
                        grid-column-start: unset;
                        grid-column-end: unset;
                        padding: 0;
                        padding-top: rem-calc(8);
                        text-align: center;
                        p {
                            font-size: rem-calc(14);
                            color: lighten($text, 10%);
                        }
                    }
                }
                .misc {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    .detail-button {
                        height: rem-calc(45);
                        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='25' ry='25' stroke='%23707070FF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='100' stroke-linecap='square'/%3e%3c/svg%3e");
                        border-radius: rem-calc(25);
                        color: $text;
                        text-transform: uppercase;
                        padding: rem-calc(0 20);
                        font-size: rem-calc(14);
                        letter-spacing: rem-calc(0.3);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        margin-right: rem-calc(10);
                        display: none;
                        span {
                            &:before {
                                font-size: rem-calc(18);
                                margin-left: rem-calc(5);
                                transition: $bezier;
                                transform: rotate(0deg);
                                display: inline-block;
                            }
                        }
                        &:hover {
                            cursor: pointer;
                        }
                        &.open {
                            span {
                                &:before {
                                    font-size: rem-calc(18);
                                    margin-left: rem-calc(5);
                                    transition: $bezier;
                                    transform: rotate(180deg);
                                    display: inline-block;
                                }
                            }
                        }
                    }
                    .affiliate-button {
                        text-decoration: none;
                        background-color: $green;
                        color: white;
                        border-radius: rem-calc(25);
                        box-shadow: $bs;
                        height: rem-calc(45);
                        padding: rem-calc(0 20);
                        font-size: rem-calc(14);
                        letter-spacing: rem-calc(0.3);
                        text-transform: uppercase;
                        width: rem-calc(125);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        transition: $bezier;
                        span {
                            &:before {
                                font-size: rem-calc(18);
                                margin-left: rem-calc(8);
                            }
                        }
                        &:hover {
                            transition: $bezier;
                            background-color: lighten($green, 5%);
                        }
                        p {
                            &.ad {
                                position: absolute;
                                top: unset;
                                bottom: rem-calc(-25);
                                text-transform: capitalize;
                                font-size: rem-calc(14);
                                color: lighten($text, 10%);
                            }
                        }
                    }
                }
                .details {
                    grid-column-start: unset;
                    grid-column-end: unset;
                    padding-top: rem-calc(35);
                    display: none;
                    h1, h2, h3 {
                        margin: 0;
                        padding-bottom: rem-calc(15);
                    }
                }
            }
        }
    }
}