.hero-section {
    position: relative;
    padding: rem-calc(80 0 0);
    background-color: $gray;
    border-radius: rem-calc(12);
    width: calc(100% - 40px);
    margin: 0px auto;
    &.alt {
        background: $lightgreen;
        padding: rem-calc(80 0);
        .inner-hero {
            p {
                &.lead {
                    line-height: rem-calc(28);
                }
            }
            img {
                max-width: 100%;
                margin: 0px auto;
                object-fit: cover;
                width: rem-calc(485);
                height: rem-calc(485);
                border-radius: rem-calc(12);
            }
        }
    }
    .inner-hero {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: rem-calc(30);
        align-items: center;
        h1 {
            padding-bottom: rem-calc(15);
            max-width: rem-calc(455);
        }
        p {
            &.lead {
                font-size: rem-calc(20);
                line-height: rem-calc(32);
                font-weight: 600;
                padding-bottom: rem-calc(35); 
                max-width: rem-calc(425);
            }
        } 
        img {
            max-width: 100%;
            margin: 0px auto;
            object-fit: contain;
            width: rem-calc(350);
        }
        button {
            border-radius: rem-calc(4);
            background-color: $green;
            border-color: $green;
            box-shadow: $bs;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $text;
            padding: rem-calc(0 24);
            height: rem-calc(62);
            font-weight: bold;
            font-size: rem-calc(17);
            outline: none;
            font-family: 'Inter', sans-serif;
            letter-spacing: rem-calc(-0.3);
            text-transform: unset;
            svg {
                margin-left: rem-calc(10);
            }
        }
    }
}
/*
.css-zw3mfo-MuiModal-root-MuiDialog-root {
    button {
        border-radius: rem-calc(25);
        background-color: $green;
        box-shadow: $bs;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        padding: rem-calc(0 23);
        height: rem-calc(50);
        font-weight: bold;
        font-size: rem-calc(14);
        outline: none;
        svg {
            margin-left: rem-calc(10);
        }
    }
}*/

@media screen and (max-width: 992px) {
    .hero-section {
        position: relative;
        margin-top: rem-calc(20);
        padding: rem-calc(40 0 0);
        &.alt {
            background: $lightgreen;
            padding: rem-calc(40 25);
            .inner-hero {
                p {
                    &.lead {
                        line-height: rem-calc(22);
                    }
                }
                img {
                    max-width: rem-calc(293);
                    margin: 0px auto;
                    object-fit: cover;
                    width: 100%;
                    height: auto;
                    border-radius: rem-calc(12);
                }
            }
        }
        .inner-hero {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            column-gap: rem-calc(30);
            row-gap: rem-calc(25);
            align-items: center;
            h1 {
                font-size: rem-calc(36);
                line-height: rem-calc(46);
                padding-bottom: rem-calc(10);
                text-align: center;
                max-width: rem-calc(260);
                margin: 0px auto;
            }
            p {
                &.lead {
                    font-size: rem-calc(16);
                    line-height: rem-calc(22);
                    font-weight: 600;
                    padding-bottom: rem-calc(35); 
                    text-align: center;
                    max-width: rem-calc(290);
                    margin: 0px auto;
                }
            }
            img {
                max-width: 75%;
                margin: 0px auto;
                object-fit: contain;
            }
            button {
                border-radius: rem-calc(4);
                background-color: $green;
                border-color: $green;
                box-shadow: $bs;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $text;
                padding: rem-calc(0 24);
                height: rem-calc(62);
                font-weight: bold;
                font-size: rem-calc(17);
                outline: none;
                font-family: 'Inter', sans-serif;
                text-transform: unset;
                margin: 0px auto rem-calc(40);
                svg {
                    margin-left: rem-calc(10);
                }
            }
        }
    }
}