.singlepost {
    position: relative;
    padding-bottom: rem-calc(80);
    .top-post {
        padding: rem-calc(50 0 55);
        background: #edf2f7;
    }
    .main-divider {
        max-width: rem-calc(820);
        margin: rem-calc(50) auto;
        border-bottom: rem-calc(5) solid $green;
    }
    ul, ol {
        padding-bottom: rem-calc(15);
    }
    h1 {
        font-size: rem-calc(62);
        line-height: rem-calc(75);
        letter-spacing: rem-calc(-0.3);
        font-weight: 900;
        padding-bottom: rem-calc(30);
    }
    h2 {
        font-size: rem-calc(36);
        line-height: rem-calc(46);
        letter-spacing: rem-calc(-0.3);
        font-weight: bold;
        padding-bottom: rem-calc(25);
    }
    h3 {
        font-size: rem-calc(26);
        line-height: rem-calc(34);
        letter-spacing: rem-calc(-0.3);
        font-weight: bold;
        padding-bottom: rem-calc(15);
    }
    p {
        font-size: rem-calc(18);
        letter-spacing: rem-calc(-0.3);
        padding-bottom: rem-calc(15);
        margin: 0;
        &.lead {
            font-size: rem-calc(28);
            line-height: rem-calc(40);
            padding-bottom: rem-calc(35);
            max-width: rem-calc(1049);
            letter-spacing: rem-calc(-0.3);
        }
        a {
            font-weight: bold;
            text-decoration: none;
            color: $green;
        }
    }
    figure {
        position: relative;
        height: 0;
        padding-bottom: 52%;
        margin-bottom: rem-calc(15);
        img {
            position: absolute;
            height: 100%;
            object-fit: cover;
            width: 100%;
            border-radius: rem-calc(12);
        }
    }
    blockquote {
        border-left: rem-calc(5) solid $green;
        padding: rem-calc(30 20);
        font-size: rem-calc(20);
        font-style: italic;
        border-radius: rem-calc(4);
        background-color: transparentize($color: $green, $amount: 0.8);
    }
    .post-container {
        &.start {
            padding-top: rem-calc(50);
        }
        max-width: rem-calc(900);
        margin: 0px auto;
        padding: rem-calc(0 40);
    }
    .post-cover {
        position: relative;
        height: 0;
        padding-bottom: 47%;
        margin-bottom: rem-calc(30);
        img {
            position: absolute;
            height: 100%;
            object-fit: cover;
            width: 100%;
            border-radius: rem-calc(12);
        }
    }
    .under-post-cover {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: rem-calc(50);
        .author-info {
            display: flex;
            .author-info-desc {
                margin-left: rem-calc(10);
                display: flex;
                flex-flow: column;
                p {
                    padding: 0;
                    font-size: rem-calc(14);
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .singlepost {
        position: relative;
        padding: rem-calc(0 0 40);
        .top-post {
            padding: rem-calc(25 0 15);
        }
        .main-divider {
            max-width: calc(100vw - 40px);
            margin: rem-calc(20) auto;
            border-bottom: rem-calc(4) solid $green;
        }
        h1 {
            font-size: rem-calc(26);
            line-height: rem-calc(34);
            font-weight: 900;
            padding-bottom: rem-calc(15);
        }
        h2 {
            font-size: rem-calc(22);
            line-height: rem-calc(27);
            font-weight: bold;
            padding-bottom: rem-calc(10);
        }
        h3 {
            font-size: rem-calc(18);
            line-height: rem-calc(24);
            font-weight: bold;
            padding-bottom: rem-calc(10);
        }
        p {
            font-size: rem-calc(16);
            padding-bottom: rem-calc(10);
            margin: 0;
            &.lead {
                font-size: rem-calc(18);
                line-height: rem-calc(26);
                padding-bottom: rem-calc(20);
            }
            a {
                font-weight: bold;
                text-decoration: none;
                color: $green;
            }
        }
        figure {
            position: relative;
            height: 0;
            padding-bottom: 52%;
            margin-bottom: rem-calc(15);
            img {
                position: absolute;
                height: 100%;
                object-fit: cover;
                width: 100%;
                border-radius: rem-calc(12);
            }
        }
        blockquote {
            border-left: rem-calc(4) solid $green;
            padding: rem-calc(15 10);
            font-size: rem-calc(16);
            font-style: italic;
            border-radius: rem-calc(4);
            background-color: transparentize($color: $green, $amount: 0.8);
        }
        .post-container {
            &.start {
                padding-top: rem-calc(25);
            }
            max-width: calc(100vw - 40px);
            margin: 0px auto;
            padding: 0;
        }
        .container {
            max-width: calc(100vw - 40px);
            padding: 0;
        }
        .post-cover {
            position: relative;
            height: 0;
            padding-bottom: 62%;
            margin-bottom: rem-calc(15);
            img {
                position: absolute;
                height: 100%;
                object-fit: cover;
                width: 100%;
                border-radius: rem-calc(12);
            }
        }
        .under-post-cover {
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            justify-content: space-between;
            padding: rem-calc(0 7);
            margin-bottom: rem-calc(25);
            .author-info {
                display: flex;
                margin-bottom: rem-calc(15);
                .css-1wlk0hk-MuiAvatar-root {
                    width: rem-calc(30);
                    height: rem-calc(30);
                }
                .author-info-desc {
                    margin-left: rem-calc(7);
                    display: flex;
                    flex-flow: column;
                    p {
                        padding: 0;
                        font-size: rem-calc(12);
                    }
                }
            }
        }
    }
}