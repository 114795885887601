.infobox {
    background-color: $lightgreen;
    padding: rem-calc(80 0);
    width: calc(100vw - 40px);
    border-radius: rem-calc(12);
    margin: rem-calc(50) auto;
    .infobox-extension {
        border-radius: rem-calc(12);
        position: relative; 
        .inner {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: rem-calc(156);
            align-items: center;
            .left {
                h2 {
                    padding-bottom: rem-calc(25);
                    letter-spacing: rem-calc(-0.3);
                }
                p {
                    font-size: rem-calc(20);
                    line-height: rem-calc(32);
                    font-weight: 600;
                    padding-bottom: rem-calc(25); 
                    max-width: rem-calc(425);
                    letter-spacing: rem-calc(-0.3);
                }
                button {
                    border-radius: rem-calc(4);
                    background-color: $text;
                    border-color: $text;
                    box-shadow: $bs;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    padding: rem-calc(0 24);
                    height: rem-calc(62);
                    font-weight: bold;
                    font-size: rem-calc(17);
                    outline: none;
                    font-family: 'Inter', sans-serif;
                    letter-spacing: rem-calc(-0.3);
                    text-transform: unset;
                    svg {
                        margin-left: rem-calc(10);
                    }
                }
                .line {
                    height: rem-calc(5);
                    margin-top: rem-calc(30);
                    margin-bottom: rem-calc(30);
                    background-color: transparentize($color: #312929, $amount: .93);
                }
                .infoboxes {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    column-gap: rem-calc(150);
                    row-gap: rem-calc(25);
                    .single-box {
                        p {
                            font-size: rem-calc(18);
                            line-height: rem-calc(26);
                            font-weight: 500;
                            letter-spacing: rem-calc(-0.3);
                            padding-bottom: 0;
                            span {
                                font-size: rem-calc(32);
                                line-height: 1;
                                font-weight: 900;
                                letter-spacing: rem-calc(-0.3);
                            }
                        }
                    }
                }
            }
            .right {
                img {
                    border-radius: rem-calc(12);
                    
                    object-fit: contain;
                    max-width: 100%;
                    margin: 0px auto;
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .infobox {
        background-color: $lightgreen;
        padding: rem-calc(40 50);
        width: calc(100vw - 40px);
        border-radius: rem-calc(12);
        margin: rem-calc(25) auto;
        .infobox-extension {
            border-radius: rem-calc(12);
            position: relative; 
            .inner {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                column-gap: rem-calc(0);
                .left {
                    order: 0 !important;
                    h2 {
                        padding-bottom: rem-calc(25);
                        font-size: rem-calc(32);
                        line-height: rem-calc(42);
                        padding-bottom: rem-calc(15);
                        text-align: center;
                    }
                    p {
                        font-size: rem-calc(16);
                        line-height: rem-calc(24);
                        font-weight: 600;
                        padding-bottom: rem-calc(25); 
                        max-width: rem-calc(425);
                        text-align: center;
                        margin: 0px auto;
                    }
                    button {
                        border-radius: rem-calc(4);
                        background-color: $text;
                        border-color: $text;
                        box-shadow: $bs;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: white;
                        padding: rem-calc(0 24);
                        height: rem-calc(62);
                        font-weight: bold;
                        font-size: rem-calc(17);
                        outline: none;
                        font-family: 'Inter', sans-serif;
                        letter-spacing: rem-calc(-0.3);
                        text-transform: unset;
                        margin: 0px auto;
                        svg {
                            margin-left: rem-calc(10);
                        }
                    }
                    .line {
                        height: rem-calc(5);
                        margin-top: rem-calc(20);
                        margin-bottom: rem-calc(20);
                        background-color: transparentize($color: #312929, $amount: .93);
                    }
                    .infoboxes {
                        display: grid;
                        grid-template-columns: 1fr;
                        column-gap: rem-calc(25);
                        row-gap: rem-calc(25);
                        margin-bottom: rem-calc(35);
                        .single-box {
                            p {
                                font-size: rem-calc(16);
                                line-height: rem-calc(24);
                                font-weight: 500;
                                letter-spacing: rem-calc(-0.3);
                                padding-bottom: 0;
                                span {
                                    font-size: rem-calc(26);
                                    line-height: 1;
                                    font-weight: 900;
                                    letter-spacing: rem-calc(-0.3);
                                }
                            }
                        }
                    }
                }
                .right {
                    img {
                        border-radius: rem-calc(12);
                        
                        object-fit: contain;
                        max-width: 100%;
                        margin: 0px auto;
                    }
                }
            }
        }
    }
}