.services-section {
    padding: rem-calc(80 0);
    position: relative;
    h2 {
        text-align: center;
        padding-bottom: rem-calc(35);
    }
    p {
        &.ingress {
            padding-bottom: rem-calc(80);
            font-size: rem-calc(20);
            line-height: rem-calc(32);
            color: #7E7E7E;
            font-weight: 500;
            max-width: rem-calc(750);
            text-align: center;
            margin: 0px auto;
        }
    }
    .services-holder {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: rem-calc(80);
        row-gap: rem-calc(55);
        .single-service {
            padding: rem-calc(25 35);
            background: $gray;
            border-radius: 25px;
            text-align: center;
            .icon {
                display: inline-flex;
                height: rem-calc(50);
                width: rem-calc(50);
                justify-content: center;
                align-items: center;
                background-color: rgba(101, 190, 126, .1);
                border-radius: 50%;
                margin-bottom: rem-calc(15);
                img {
                    width: rem-calc(28);
                }
            }
            h3 {
                margin: 0;
                padding-bottom: rem-calc(8);
            }
            p {
                color: #787F82;
                font-weight: 500;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .services-section {
        padding: rem-calc(40 0);
        position: relative;
        h2 {
            font-size: rem-calc(28);
            line-height: rem-calc(28);
            text-align: center;
            padding-bottom: rem-calc(20);
        }
        p {
            &.ingress {
                padding-bottom: rem-calc(40);
                font-size: rem-calc(16);
                line-height: rem-calc(22);
                color: #7E7E7E;
                font-weight: 500;
                max-width: rem-calc(350);
                text-align: center;
                margin: 0px auto;
            }
        }
        .services-holder {
            display: grid;
            grid-template-columns: 1fr;
            column-gap: 0;
            row-gap: rem-calc(0);
            text-align: center;
            max-width: 100%;
            margin: 0px auto;
            background: $gray;
            border-radius: rem-calc(25);
            padding: rem-calc(15 25);
            .single-service {
                padding: rem-calc(15 0);
                text-align: left;
                background: transparent;
                .icon {
                    display: inline-flex;
                    height: rem-calc(45);
                    width: rem-calc(45);
                    justify-content: center;
                    align-items: center;
                    background-color: rgba(101, 190, 126, .1);
                    border-radius: 50%;
                    margin-bottom: rem-calc(15);
                    img {
                        width: rem-calc(24);
                    }
                }
                h3 {
                    font-size: rem-calc(18);
                    line-height: rem-calc(24);
                    margin: 0;
                    padding-bottom: rem-calc(5);
                }
                p {
                    color: #787F82;
                    font-weight: 500;
                }
            }
        }
    }
}