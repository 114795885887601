.discounts {
    position: relative;
    padding: rem-calc(50 0);
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: rem-calc(30);
    .single-discount {
        background: $gray;
        border-radius: rem-calc(12);
        padding: rem-calc(35);
        display: grid;
        grid-template-columns: minmax(190px, 1fr) 2fr 1fr;
        grid-column-gap: rem-calc(85);
        border: rem-calc(2) solid #f0f0f0;
        position: relative;
        &.boost {
            border: rem-calc(2) solid $green;
        }
        .chip-holder {
            position: absolute;
            top: rem-calc(-17);
            left: 50%;
            transform: translateX(-50%);
        }
        .main-info {
            display: flex;
            align-items: center;
            img {
                width: rem-calc(200);
                height: auto;
                object-fit: contain;
                margin-right: rem-calc(20);
            }
            .details {
                display: flex;
                flex-flow: column;
                max-width: rem-calc(190);
                h3 {
                    font-size: rem-calc(20);
                    line-height: rem-calc(28);
                    margin-top: rem-calc(10);
                }
                p {
                    line-height: 1;
                    small {
                        font-size: rem-calc(10);
                        font-weight: 400;
                    }
                }
                img {
                    width: rem-calc(30);
                    margin-right: unset;
                }
            }
        }
        .main-info-tekst {
            .details {
                display: flex;
                flex-flow: column;
                h3 {
                    font-size: rem-calc(20);
                    line-height: rem-calc(28);
                    margin-top: rem-calc(10);
                }
                p {
                    line-height: 1;
                    small {
                        font-size: rem-calc(10);
                        font-weight: 400;
                    }
                }
            }
        }
        .features {
            display: flex;
            flex-flow: column;
            justify-content: space-around;
            p {
                display: flex;
                justify-content: space-between;
                font-size: rem-calc(12);
                span {
                    font-weight: 600;
                }
            }
        }
        .benefits {
            display: flex;
            flex-flow: column;
            justify-content: space-around;
            p {
                display: flex;
                align-items: center;
                font-size: rem-calc(12);
                span {
                    font-weight: 600;
                    margin-left: rem-calc(10);
                }
            }
        }
        .buttons {
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-flow: column;
            position: relative;
            a {
                position: relative;
                small {
                    display: none;
                    text-align: center;
                    font-size: rem-calc(10);
                    position: absolute;
                    top: rem-calc(-16);
                    transform: translateX(-50%);
                    left: 50%;
                }
            }
            button {
                &.alt {
                    background: white;
                    border-color: $green;
                    border-style: dashed;
                    color: $text;
                    font-weight: 400;
                }
                border-radius: rem-calc(4);
                background-color: $text;
                border-color: $text;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                padding: rem-calc(0 24);
                height: rem-calc(50);
                font-weight: bold;
                font-size: rem-calc(17);
                outline: none;
                font-family: 'Inter', sans-serif;
                letter-spacing: rem-calc(0.3);
                text-transform: unset;
                svg {
                    margin-right: rem-calc(10);
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .discounts {
        position: relative;
        padding: rem-calc(25 0);
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: rem-calc(30);
        .single-discount {
            background: $gray;
            border-radius: rem-calc(12);
            padding: rem-calc(25);
            display: grid;
            grid-template-columns: 1fr;
            grid-column-gap: rem-calc(45);
            grid-row-gap: rem-calc(15);
            .main-info {
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: rem-calc(120);
                    height: auto;
                    object-fit: contain;
                    margin-right: rem-calc(15);
                    margin-top: rem-calc(10);
                }
                .details {
                    display: flex;
                    flex-flow: column;
                    max-width: rem-calc(190);
                    h3 {
                        font-size: rem-calc(16);
                        line-height: rem-calc(22);
                        margin-top: rem-calc(8);
                    }
                    p {
                        line-height: rem-calc(10);
                        small {
                            font-size: rem-calc(8);
                            font-weight: 400;
                        }
                    }
                    img {
                        width: rem-calc(20);
                        margin-right: unset;
                        display: none;
                    }
                }
            }
            .main-info-text {
                h3 {
                    font-size: rem-calc(20);
                    line-height: rem-calc(28);
                    margin-top: rem-calc(0);
                    text-align: center;
                    margin-bottom: rem-calc(10);
                }
                p {
                    line-height: 1;
                    text-align: center;
                    small {
                        font-size: rem-calc(10);
                        font-weight: 400;
                    }
                }
            }
            .buttons {
                display: flex;
                align-items: center;
                justify-content: space-around;
                flex-flow: row;
                button {
                    &.alt {
                        background: white;
                        border-color: $green;
                        border-style: dashed;
                        color: $text;
                        font-weight: 400;
                        font-size: rem-calc(14) !important;
                    }
                    border-radius: rem-calc(4);
        
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    padding: rem-calc(0 18);
                    height: rem-calc(45);
                    font-weight: bold;
                    outline: none;
                    font-family: 'Inter', sans-serif;
                    letter-spacing: rem-calc(0.3);
                    text-transform: unset;
                    font-size: rem-calc(14) !important;
                    svg {
                        margin-right: rem-calc(8);
                        font-size: 99%;
                    }
                }
            }
        }
    }
    
}