$rem-base: 16px;

@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@function convert-to-rem($value, $base-value: $rem-base)  {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;
  @if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
  @return $value;
}

@function rem-calc($values, $base-value: $rem-base) {
  $max: length($values);
  @if $max == 1 { @return convert-to-rem(nth($values, 1), $base-value); }
  $remValues: ();
  @for $i from 1 through $max {
    $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
  }
  @return $remValues;
}

$bezier: all 0.2s ease-in-out;
$bs: rem-calc(0 3 6) rgba(0,0,0, 0.16);
$green: #65bf7f;
$lightgreen: #B7F498;
$red: #D96363;
$gray: #F8F8F8;
$text: #333333;
$yellow: #FFEA79;

html,
body {
    margin: 0;
    padding: 0;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    font-family: 'Inter', sans-serif;
    color: #333030;
}

.container {
    max-width: rem-calc(1290);
    margin: 0px auto;
    width: 100%;
    padding: rem-calc(0 40);
}

@media screen and (max-width: 992px) {
    .container {
        max-width: calc(100vw - 40px);
        margin: 0px auto;
        padding: 0;
        width: 100%;
    }
}

h1 {
    font-size: rem-calc(62);
    font-weight: 900;
    line-height: rem-calc(75);
    letter-spacing: rem-calc(-0.3);
}

h2 {
    font-size: rem-calc(48);
    font-weight: 900;
    line-height: rem-calc(59);
    letter-spacing: rem-calc(-0.3);
}

h3 {
    font-size: rem-calc(20);
    font-weight: 700;
    line-height: rem-calc(27);
    letter-spacing: rem-calc(-0.3);
}

button {
    &:focus {
        outline: none !important;
    }
}

.MuiChip-colorSuccess {
    background-color: $green !important;
}

.radio-wrapper {
    
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      row-gap: rem-calc(25);
    }
    
    ul li {
      display: block;
      position: relative;
      min-height: rem-calc(55);
      min-width: rem-calc(120);
      display: flex;
      align-items: center;
      flex-flow: row-reverse;
      position: relative;
      margin-right: rem-calc(25);
      &:last-child {
          margin: 0;
      }
    }
    
    ul li input[type=radio] {
      position: absolute;
      visibility: hidden;
    }
    
    ul li label {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      font-family: "Roboto","Helvetica","Arial",sans-serif;
      font-size: rem-calc(16);
      min-width: rem-calc(120);
      min-height: rem-calc(55);
      z-index: 9;
      cursor: pointer;
      padding: rem-calc(10 25);
      transition: $bezier;
      border-radius: rem-calc(4);
      border: rem-calc(1) dashed #e4e6ef;
      background-color: #f0f0f0;
      &:last-child {
        margin: 0;
      }
    }
    ul li label:hover {
        transition: $bezier;
        color: $green;
        border: rem-calc(1) dashed $green;
        background-color: transparentize($color: $green, $amount: 0.9);
    }
    
    ul li .check {
      display: none;
    }
    
    input[type=radio]:checked ~ .check {
      border: rem-calc(2) solid rgba(255,255,255, 0.5);
      transition: $bezier;
    }
    
    input[type=radio]:checked ~ .check::before{
      background: white;
      transition: $bezier;
    }
    
    input[type=radio]:checked ~ label {
      color: $green;
      transition: $bezier;
      background-color: transparentize($color: $green, $amount: 0.9);
      border: rem-calc(1) dashed $green;
    }
  }

  @media screen and (max-width: 992px) {
    .radio-wrapper {
    
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;
          flex-wrap: wrap;
          row-gap: rem-calc(15);
          column-gap: 0;
        }
        
        ul li {
          display: block;
          position: relative;
          min-height: rem-calc(45);
          min-width: rem-calc(100);
          display: flex;
          align-items: center;
          flex-flow: row-reverse;
          position: relative;
        }
        
        ul li input[type=radio] {
          position: absolute;
          visibility: hidden;
        }
        
        ul li label {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          font-size: rem-calc(16);
          min-width: rem-calc(100);
          min-height: rem-calc(45);
          z-index: 9;
          cursor: pointer;
          padding: rem-calc(0 25);
          transition: $bezier;
          border-radius: rem-calc(4);
          border: rem-calc(1) dashed #e4e6ef;
          background-color: #f0f0f0;
          &:last-child {
            margin: 0;
          }
        }
        ul li label:hover {
            transition: $bezier;
            color: $green;
            border: rem-calc(1) dashed $green;
            background-color: transparentize($color: $green, $amount: 0.9);
        }
        
        ul li .check {
          display: none;
        }
        
        input[type=radio]:checked ~ .check {
          border: rem-calc(2) solid rgba(255,255,255, 0.5);
          transition: $bezier;
        }
        
        input[type=radio]:checked ~ .check::before{
          background: white;
          transition: $bezier;
        }
        
        input[type=radio]:checked ~ label {
          color: $green;
          transition: $bezier;
          background-color: transparentize($color: $green, $amount: 0.9);
          border: rem-calc(1) dashed $green;
        }
      }
  }

  .error-span {
      color: $red;
      font-size: rem-calc(14);
      padding-left: rem-calc(5);
      display: inline-block;
      margin: 0 !important;
  }

  .css-grloze-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: rgba(0, 0, 0, 0.6) !important;
  }

  .css-4som2l-MuiTypography-root a {
      color: $green !important;
  }

  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
  }

@import 'components/header.scss';
@import 'components/footer.scss';
@import 'components/posts.scss';
@import 'components/services.scss';
@import 'components/hero.scss';
@import 'components/loan-list.scss';
@import 'components/single.scss';
@import 'components/wordcloud.scss';
@import 'components/infobox.scss';
@import 'components/creditcards.scss';
@import 'components/discounts.scss';

body {
    div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner p {
        -webkit-font-smoothing: antialiased;
        font-family: 'Inter', sans-serif !important;
        font-size: rem-calc(16) !important;
        font-weight: 600 !important;
        color: $text !important;
        margin: 0;
        a {
           font-weight: 600 !important;
           color: white !important;
        }
    }
    #hs-eu-policy-wording {
        margin: 0 !important;
    }
}

#hs-eu-cookie-confirmation-inner {
    display: flex;
    align-items: center;
}

body {
    #hs-eu-cookie-confirmation {
        background: $green !important;
    }
    div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner {
        background: $green !important;
    
    }
    div#hs-eu-cookie-confirmation {
        border: none;
        box-shadow: none !important;
    }
    div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner a#hs-eu-confirmation-button {
        background-color: $text !important;
        border: none !important;
        font-family: 'Inter', sans-serif !important;
        font-size: rem-calc(16) !important;
        color: #fff;
        font-family: inherit;
        font-size: inherit;
        font-weight: 600!important;
        line-height: inherit;
        text-align: left;
        text-shadow: none!important;
        margin-left: 25px !important;
        width: 150px;
        text-align: center;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.thank-you {
    img {
        width: 100%;
        max-width: rem-calc(400);
    }
}

.loading-holder {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: white;
    top: 0;
    left: 0;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    p {
        padding-top: rem-calc(25);
    }
}

.loader {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: $green $green transparent transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after,
  .loader::before {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent $lightgreen $lightgreen;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
  }
  .loader::before {
    width: 32px;
    height: 32px;
    border-color: $green $green transparent transparent;
    animation: rotation 1.5s linear infinite;
  }
      
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
      