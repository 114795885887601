header {
    position: relative;
    background: white;
    min-height: rem-calc(105);
    display: flex;
    align-items: center;
    z-index: 99;
    .header-holder {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .menu {
            .side-wrapper {
                display: flex;
                align-items: center;
                .faen {
                    position: fixed;
                    left: 50%;
                    bottom: rem-calc(35);
                    transform: translateX(-50%);
                    background: white;
                    padding: rem-calc(15 25);
                    display: flex;
                    align-items: center;
                    border-radius: rem-calc(25);
                    box-shadow: $bs;
                    p {
                        font-size: rem-calc(18);
                        font-weight: bold;
                    }
                }
                button {
                    &.logout {
                        background: white;
                        border-radius: rem-calc(5);
                        padding: rem-calc(10 25);
                        position: relative;
                        border: rem-calc(1) solid transparentize($color: #707070, $amount: .75);
                        font-weight: bold;
                        svg {
                            margin-left: rem-calc(10);
                            width: rem-calc(19);
                        }
                    }
                    &.loginbtn {
                        background: white;
                        border-radius: rem-calc(5);
                        padding: rem-calc(10 25);
                        position: relative;
                        border: rem-calc(1) solid transparentize($color: #707070, $amount: .75);
                        font-weight: bold;
                        .red-circ {
                            background: #D96363;
                            width: rem-calc(8);
                            height: rem-calc(8);
                            position: absolute;
                            display: inline-block;
                            border-radius: rem-calc(25);
                            animation: blink 1.5s infinite ease 0s;
                        }
                    }
                    svg {
                        margin-left: rem-calc(5);
                        width: rem-calc(22);
                    }
                    &.sticky-btn {
                        border-radius: rem-calc(4);
                        background-color: $green;
                        border-color: $green;
                        box-shadow: $bs;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: $text;
                        padding: rem-calc(0 24);
                        height: rem-calc(62);
                        font-weight: bold;
                        font-size: rem-calc(17);
                        outline: none;
                        font-family: 'Inter', sans-serif;
                        letter-spacing: rem-calc(-0.3);
                        text-transform: unset;
                        svg {
                            margin-left: rem-calc(10);
                        }
                    }
                }
            }
        }
        .logo-holder {
            svg {
                width: rem-calc(257);
                height: auto;
            }
        }
    }
}
@media screen and (max-width: 992px) {
    header {
        position: relative;
        background: white;
        min-height: rem-calc(70);
        display: flex;
        align-items: center;
        z-index: 99;    
        .header-holder {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .logo-holder {
                svg {
                    width: rem-calc(174);
                    height: auto;
                }
            }
            
            .menu {
                .side-wrapper {
                    button {
                        &.loginbtn {
                            background: white;
                            border-radius: rem-calc(5);
                            padding: rem-calc(3 18);
                            position: relative;
                            border: rem-calc(1) solid transparentize($color: #707070, $amount: .75);
                            font-size: rem-calc(14);
                            top: rem-calc(5);
                            .red-circ {
                                background: #D96363;
                                width: rem-calc(8);
                                height: rem-calc(8);
                                position: absolute;
                                display: inline-block;
                                border-radius: rem-calc(25);
                                top: rem-calc(8);
                            }
                            svg {
                                width: rem-calc(20);
                            }
                        }
                    }
                    
                        .faen {
                            position: fixed;
                            left: 50%;
                            bottom: rem-calc(35);
                            transform: translateX(-50%);
                            background: white;
                            padding: rem-calc(15);
                            display: flex;
                            flex-flow: column;
                            justify-content: center;
                            text-align: center;
                            align-items: center;
                            border-radius: rem-calc(25);
                            box-shadow: $bs;
                            display: none;
                            p {
                                font-size: rem-calc(16);
                                padding-bottom: rem-calc(8);
                                font-weight: bold;
                            }
                        }
                    }
                    svg {
                        width: rem-calc(59);
                        height: rem-calc(32);
                    }
                
            }
            
        }
    }
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}