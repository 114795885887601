.post-section {
    position: relative;
    padding: rem-calc(80 0);
    background-color: $gray;
    h2 {
        font-size: rem-calc(48);
        line-height: rem-calc(59);
        font-weight: 900;
        text-align: center;
        padding-bottom: rem-calc(80);
    }
    button {
        border-radius: rem-calc(4);
        background-color: $text;
        border-color: $text;
        box-shadow: $bs;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        padding: rem-calc(0 24);
        height: rem-calc(62);
        font-weight: bold;
        font-size: rem-calc(17);
        outline: none;
        font-family: 'Inter', sans-serif;
        letter-spacing: rem-calc(-0.3);
        text-transform: unset;
        svg {
            margin-left: rem-calc(10);
        }
        &:hover {
            background-color: $text;
            border-color: $text;
        }
    }
    .post-holder {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: rem-calc(50);
        .single-post {
            border-radius: rem-calc(12);
            position: relative;
            &:hover {
                .post-info {
                    a {
                        svg {
                            margin-left: rem-calc(12);
                            transition: $bezier;
                        }
                    }
                }
            }
            img {
                object-fit: cover;
                height: rem-calc(360);
                width: 100%;
                border-radius: rem-calc(12);
                border: 1px solid #a0aec038;
            }
            .post-info {
                padding: rem-calc(25 25 15);
                max-width: 90%;
                width: 100%;
                margin: 0px auto;
                box-shadow: $bs;
                position: relative;
                background-color: white;
                margin-top: rem-calc(-40);
                z-index: 10;
                border-radius: rem-calc(12);
                h3 {
                    font-weight: bold;
                    font-size: rem-calc(22);
                    line-height: rem-calc(28);
                    padding-bottom: rem-calc(20);
                    letter-spacing: rem-calc(-0.3);
                }
                .cat-ribbon {
                    margin-right: rem-calc(10);
                    padding: rem-calc(3 8);
                    border-radius: rem-calc(12); 
                    font-size: rem-calc(12);
                    font-weight: 500;
                }
                p {
                    border-top: rem-calc(1) solid #E2E2E2;
                    padding-top: rem-calc(15);
                    margin-top: rem-calc(15);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: #7E7E7E;
                    font-size: rem-calc(12);
                    div {
                        display: flex;
                        align-items: center;
                    }
                    img {
                        border-radius: 50%;
                        width: rem-calc(40);
                        height: rem-calc(40);
                        margin-right: rem-calc(10);
                    }
                }
                a {
                    color: $green;
                    font-size: rem-calc(20);
                    svg {
                        margin-left: rem-calc(4);
                        transition: $bezier;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .post-section {
        position: relative;
        padding: rem-calc(40 0);
        h2 {
            font-size: rem-calc(22);
            line-height: rem-calc(27);
            font-weight: bold;
            text-align: center;
            padding-bottom: rem-calc(25);
        }
        .post-holder {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            column-gap: rem-calc(30);
            row-gap: rem-calc(25);
            .single-post {
                border-radius: rem-calc(12);
                &:hover {
                    .post-info {
                        a {
                            svg {
                                margin-left: rem-calc(12);
                                transition: $bezier;
                            }
                        }
                    }
                }
                img {
                    object-fit: cover;
                    height: rem-calc(200);
                    width: 100%;
                    border-top-left-radius: rem-calc(12);
                    border-top-right-radius: rem-calc(12);
                }
                .post-info {
                    padding: rem-calc(20);
                    max-width: 95%;
                    h3 {
                        font-weight: bold;
                        font-size: rem-calc(18);
                        line-height: rem-calc(24);
                        padding-bottom: rem-calc(15);
                    }
                    a {
                        color: $green;
                        font-size: rem-calc(16);
                        font-weight: bold;
                        svg {
                            margin-left: rem-calc(4);
                            transition: $bezier;
                            width: rem-calc(18);
                        }
                    }
                }
            }
        }
    }
}