.footer-extension {
    background-color: $lightgreen;
    padding: rem-calc(60 100);
    border-radius: rem-calc(12);
    position: relative;
    margin-top: rem-calc(100);
    margin-bottom: rem-calc(-50);
    .inner {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .left {
            h2 {
                padding-bottom: rem-calc(25);
            }
            p {
                font-size: rem-calc(20);
                line-height: rem-calc(32);
                font-weight: 600;
                padding-bottom: rem-calc(25); 
                max-width: rem-calc(425);
            }
            button {
                border-radius: rem-calc(4);
                background-color: $text;
                border-color: $text;
                box-shadow: $bs;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                padding: rem-calc(0 24);
                height: rem-calc(62);
                font-weight: bold;
                font-size: rem-calc(17);
                outline: none;
                font-family: 'Inter', sans-serif;
                letter-spacing: rem-calc(-0.3);
                text-transform: unset;
                svg {
                    margin-left: rem-calc(10);
                }
            }
        }
        .right {
            img {
                border-radius: rem-calc(12);
                width: rem-calc(293);
                object-fit: contain;
                max-width: 100%;
                margin: 0px auto;
            }
        }
    }
}
footer {
    background-color: #212121;
    padding: rem-calc(120 0 90);
    color: white;
    a {
        color: $green;
    }
    .logo-holder {
        padding-bottom: rem-calc(50);
        img {
            width: rem-calc(333);
            max-width: 100%;
            margin: 0px auto;
        }
    }
    .footer-text {
        font-size: rem-calc(16);
        max-width: rem-calc(720);
        text-align: center;
        margin: 0px auto;
    }
    .helvete {
        font-size: rem-calc(12);
        text-align: center;
        margin-top: rem-calc(25);
        padding: rem-calc(0 25);
    }
}

@media screen and (max-width: 992px) {
    .footer-extension {
        background-color: $lightgreen;
        padding: rem-calc(30 40);
        border-radius: rem-calc(12);
        position: relative;
        margin-top: rem-calc(100);
        margin-bottom: rem-calc(-50);
        .inner {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            .left {
                h2 {
                    font-size: rem-calc(32);
                    line-height: rem-calc(42);
                    padding-bottom: rem-calc(15);
                    text-align: center;
                }
                p {
                    font-size: rem-calc(16);
                    line-height: rem-calc(24);
                    font-weight: 600;
                    padding-bottom: rem-calc(25); 
                    max-width: rem-calc(425);
                    text-align: center;
                    margin: 0px auto;
                }
                button {
                    border-radius: rem-calc(4);
                    background-color: $text;
                    border-color: $text;
                    box-shadow: $bs;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    padding: rem-calc(0 24);
                    height: rem-calc(62);
                    font-weight: bold;
                    font-size: rem-calc(17);
                    outline: none;
                    font-family: 'Inter', sans-serif;
                    letter-spacing: rem-calc(-0.3);
                    text-transform: unset;
                    max-width: 100%;
                    text-align: center;
                    margin: 0px auto rem-calc(50);
                    svg {
                        margin-left: rem-calc(10);
                    }
                }
            }
            .right {
                img {
                    border-radius: rem-calc(12);
                    width: rem-calc(293);
                    object-fit: contain;
                    max-width: 100%;
                    margin: 0px auto;
                }
            }
        }
    }
    footer {
        background-color: #212121;
        padding: rem-calc(100 0 70);
        color: white;
        .logo-holder {
            padding-bottom: rem-calc(25);
            img {
                width: rem-calc(230);
                max-width: 100%;
                margin: 0px auto;
            }
        }
        .footer-text {
            font-size: rem-calc(14);
            max-width: rem-calc(720);
            text-align: center;
            margin: 0px auto;
        }
    }
}